// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphqlWs from "graphql-ws";

function makeOptions(url, connectionParams) {
  return {
          url: url,
          connectionParams: connectionParams
        };
}

function Client_make(prim) {
  return GraphqlWs.createClient(prim);
}

function Client_subscribe(prim0, prim1, prim2) {
  return prim0.subscribe(prim1, prim2);
}

var Client = {
  makeOptions: makeOptions,
  make: Client_make,
  subscribe: Client_subscribe
};

export {
  Client ,
}
/* graphql-ws Not a pure module */
