// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";

function Modal$Overlay(props) {
  return JsxRuntime.jsx(React$1.Transition.Child, {
              as: React.Fragment,
              children: Caml_option.some(JsxRuntime.jsx(React$1.Dialog.Overlay, {
                        className: "fixed inset-0 bg-gray-800 bg-opacity-25"
                      })),
              enter: "ease-out transition-opacity duration-300",
              enterFrom: "opacity-0",
              enterTo: "opacity-100",
              leave: "ease-out transition-opacity duration-200",
              leaveFrom: "opacity-100",
              leaveTo: "opacity-0"
            });
}

var Overlay = {
  make: Modal$Overlay
};

function Modal$Frame(props) {
  var __stack = props.stack;
  var onClose = props.onClose;
  var __size = props.size;
  var open_ = props.open_;
  var size = __size !== undefined ? __size : "Small";
  var stack = __stack !== undefined ? __stack : "Default";
  var tmp;
  switch (size) {
    case "Small" :
        tmp = "sm:max-w-sm";
        break;
    case "Medium" :
        tmp = "sm:max-w-xl";
        break;
    case "DrilldownModal" :
        tmp = "sm:max-w-2xl";
        break;
    case "TimelineModal" :
        tmp = "sm:max-w-[calc(100vw-8rem)]";
        break;
    
  }
  var tmp$1;
  tmp$1 = stack === "Default" ? "z-20" : "z-30";
  return JsxRuntime.jsx(React$1.Transition, {
              as: React.Fragment,
              appear: true,
              show: open_,
              children: Caml_option.some(JsxRuntime.jsx(React$1.Dialog, {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Modal$Overlay, {}),
                                JsxRuntime.jsx("span", {
                                      children: " ",
                                      "aria-hidden": true,
                                      className: "hidden sm:inline-block sm:align-middle sm:h-screen"
                                    }),
                                JsxRuntime.jsx(React$1.Transition.Child, {
                                      as: React.Fragment,
                                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                                children: props.children,
                                                className: U.tw([
                                                      "inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl",
                                                      tmp
                                                    ])
                                              })),
                                      enter: "ease-out duration-300",
                                      enterFrom: "opacity-0 scale-95",
                                      enterTo: "opacity-100 scale-100",
                                      leave: "ease-in duration-200",
                                      leaveFrom: "opacity-100 scale-100",
                                      leaveTo: "opacity-0 scale-95"
                                    })
                              ],
                              className: "min-h-screen px-4 text-center"
                            }),
                        className: U.tw([
                              "fixed inset-0 overflow-y-auto",
                              tmp$1
                            ]),
                        open: open_,
                        onClose: (function () {
                            if (onClose !== undefined) {
                              return onClose();
                            }
                            
                          })
                      }))
            });
}

var Frame = {
  make: Modal$Frame
};

function Modal$Title(props) {
  return JsxRuntime.jsx(React$1.Dialog.Title, {
              children: props.title,
              className: "pt-3 text-center text-lg leading-6 font-medium text-gray-900 font-serif",
              id: "modal-headline"
            });
}

var Title = {
  make: Modal$Title
};

function Modal$Content(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: "Content flex-grow text-center"
            });
}

var Content = {
  make: Modal$Content
};

function Modal$Icon(props) {
  return JsxRuntime.jsx("div", {
              children: props.icon,
              className: "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100"
            });
}

var Icon = {
  make: Modal$Icon
};

function Modal$Actions$SingleCloseButton(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Button.make, {
                    text: "Stäng",
                    onClick: props.onClick,
                    layout: "Static",
                    variant: "Outlined"
                  }),
              className: "flex flex-row justify-center"
            });
}

var SingleCloseButton = {
  make: Modal$Actions$SingleCloseButton
};

var Actions = {
  SingleCloseButton: SingleCloseButton
};

function Modal(props) {
  var __stack = props.stack;
  var __size = props.size;
  var icon = props.icon;
  var title = props.title;
  var size = __size !== undefined ? __size : "Small";
  var stack = __stack !== undefined ? __stack : "Default";
  var tmp;
  switch (size) {
    case "Small" :
        tmp = "24rem";
        break;
    case "Medium" :
        tmp = "32rem";
        break;
    case "DrilldownModal" :
        tmp = "42rem";
        break;
    case "TimelineModal" :
        tmp = "52rem";
        break;
    
  }
  return JsxRuntime.jsx(Modal$Frame, {
              children: JsxRuntime.jsxs("section", {
                    children: [
                      icon !== undefined ? JsxRuntime.jsx(Modal$Icon, {
                              icon: Caml_option.valFromOption(icon)
                            }) : null,
                      title !== undefined ? JsxRuntime.jsx(Modal$Title, {
                              title: title
                            }) : null,
                      JsxRuntime.jsx(Modal$Content, {
                            children: props.children
                          }),
                      JsxRuntime.jsx("div", {
                            children: props.actions,
                            className: "mt-5 sm:mt-6"
                          })
                    ],
                    className: "flex flex-col",
                    style: {
                      minHeight: tmp
                    }
                  }),
              open_: props.open_,
              size: size,
              onClose: props.onClose,
              stack: stack
            });
}

var make = Modal;

export {
  Overlay ,
  Frame ,
  Title ,
  Content ,
  Icon ,
  Actions ,
  make ,
}
/* react Not a pure module */
