// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toString(t) {
  return t;
}

function parse(str) {
  return Caml_option.some(str);
}

function classify(t) {
  var match = t.split(":");
  if (match.length !== 2) {
    return "Unknown";
  }
  var match$1 = match[0];
  switch (match$1) {
    case "a" :
        return "Analytics";
    case "b" :
        return "Bundled";
    case "c" :
        return "Synthetic";
    default:
      return "Unknown";
  }
}

var serialize = toString;

export {
  toString ,
  parse ,
  serialize ,
  classify ,
}
/* No side effect */
