// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";

function LoadingBar(props) {
  var visible = props.visible;
  var match = React.useState(function () {
        return visible;
      });
  var setRender = match[1];
  var render = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setProgress = match$1[1];
  var progress = match$1[0];
  var translate = (100 - progress * 100).toFixed();
  React.useEffect((function () {
          if (!render) {
            return ;
          }
          var timeoutId = setTimeout((function () {
                  setProgress(function (currentprogress) {
                        if (currentprogress < 0.7) {
                          return currentprogress + 0.7;
                        } else if (currentprogress < 0.8) {
                          return currentprogress + 0.05;
                        } else if (currentprogress < 0.95) {
                          return currentprogress + 0.025;
                        } else {
                          return progress + 0.005;
                        }
                      });
                }), progress < 0.7 ? 0 : (
                  progress < 0.8 ? 800 : (
                      progress < 0.95 ? 1000 : 1500
                    )
                ));
          return (function () {
                    clearTimeout(timeoutId);
                  });
        }), [
        progress,
        setProgress,
        render
      ]);
  React.useEffect((function () {
          if (visible) {
            var timeoutId = setTimeout((function () {
                    setProgress(function (param) {
                          return 0;
                        });
                    setRender(function (param) {
                          return true;
                        });
                  }), 300);
            return (function () {
                      clearTimeout(timeoutId);
                    });
          }
          var renderTimeoutId = setTimeout((function () {
                  setRender(function (param) {
                        return false;
                      });
                }), 200);
          var progressTimeoutId = setTimeout((function () {
                  setProgress(function (param) {
                        return 0;
                      });
                }), 500);
          setProgress(function (param) {
                return 1;
              });
          return (function () {
                    clearTimeout(renderTimeoutId);
                    clearTimeout(progressTimeoutId);
                  });
        }), [
        setRender,
        visible
      ]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    className: U.tw([
                          "absolute top-0 left-0, bottom-0 w-full rounded-full bg-blue-500 transition-all duration-200",
                          render && progress !== 0 ? "opacity-100" : "",
                          render ? "-translate-y-2" : "opacity-0"
                        ]),
                    style: {
                      transform: "translateX(-" + translate + "%)"
                    }
                  }),
              className: "pointer-events-none w-full h-1 relative overflow-hidden"
            });
}

var make = LoadingBar;

export {
  make ,
}
/* react Not a pure module */
