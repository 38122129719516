// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GlobalAppState from "./utils/GlobalAppState.mjs";
import * as LastVisitedOrganization from "./utils/LastVisitedOrganization.mjs";

function AppInitializers(props) {
  LastVisitedOrganization.useTrackLastVisitedOrganization();
  GlobalAppState.useInitGlobalAppState();
  return null;
}

var make = AppInitializers;

export {
  make ,
}
/* GlobalAppState Not a pure module */
