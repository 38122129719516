// Generated by ReScript, PLEASE EDIT WITH CARE

import Informind_logoSvg from "../assets/img/informind_logo.svg";
import Informind_symbolSvg from "../assets/img/informind_symbol.svg";
import Google_signin_focusPng from "../assets/img/google_signin_focus.png";
import Google_signin_normalPng from "../assets/img/google_signin_normal.png";
import Facebook_f_logo_whitePng from "../assets/img/facebook_f_logo_white.png";
import Informind_logo_white_textSvg from "../assets/img/informind_logo_white_text.svg";

var logotypeWhiteText = Informind_logo_white_textSvg;

var logotype = Informind_logoSvg;

var symbol = Informind_symbolSvg;

var normal = Google_signin_normalPng;

var focus = Google_signin_focusPng;

var GoogleButton = {
  normal: normal,
  focus: focus
};

var fLogo = Facebook_f_logo_whitePng;

var FacebookButton = {
  fLogo: fLogo
};

export {
  logotypeWhiteText ,
  logotype ,
  symbol ,
  GoogleButton ,
  FacebookButton ,
}
/* logotypeWhiteText Not a pure module */
