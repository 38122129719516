// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  return str;
}

function serialize(t) {
  return t;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
