// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  switch (str) {
    case "channels" :
        return "Channels";
    case "sources" :
        return "Sources";
    default:
      return ;
  }
}

function serialize(t) {
  if (t === "Channels") {
    return "channels";
  } else {
    return "sources";
  }
}

var TopListsVisible = {
  parse: parse,
  serialize: serialize
};

export {
  TopListsVisible ,
}
/* No side effect */
