// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icons from "../Icons.mjs";
import * as Login from "./Login.mjs";
import * as Modal from "./Modal.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as LoginModalUtils from "./LoginModalUtils.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function reducer(param, action) {
  if (action === "SetVisible") {
    return {
            visible: true
          };
  } else {
    return {
            visible: false
          };
  }
}

function LoginModal(props) {
  var match = React.useReducer(reducer, {
        visible: false
      });
  var dispatch = match[1];
  var addToast = Toast.useAddToast();
  React.useEffect((function () {
          LoginModalUtils.LoginTrigger.setTriggerLoginCallback(function () {
                dispatch("SetVisible");
              });
        }), [dispatch]);
  if (match[0].visible) {
    return JsxRuntime.jsxs(Modal.Frame.make, {
                children: [
                  JsxRuntime.jsx(Modal.Icon.make, {
                        icon: Icons.lock("w-8 h-8 text-gray-800")
                      }),
                  JsxRuntime.jsx(Modal.Title.make, {
                        title: "Logga in"
                      }),
                  JsxRuntime.jsx(Modal.Content.make, {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Du har blivit utloggad och behöver logga in igen.",
                                      className: "text-gray-500 text-md mb-6"
                                    }),
                                JsxRuntime.jsx(Login.make, {
                                      onDone: (function () {
                                          addToast({
                                                title: "Du har loggats in igen",
                                                message: "Välkommen tillbaka.",
                                                typ: "Success"
                                              });
                                          dispatch("SetHidden");
                                        })
                                    })
                              ],
                              className: "mb-4"
                            })
                      })
                ],
                open_: true
              });
  } else {
    return null;
  }
}

var make = LoginModal;

export {
  reducer ,
  make ,
}
/* Icons Not a pure module */
