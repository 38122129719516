// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Icons from "../Icons.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

function InlineNotification(props) {
  var children = props.children;
  var typ = props.typ;
  var text = props.text;
  var tmp;
  switch (typ) {
    case "Warning" :
        tmp = Icons.exclamationCircle("h-5 w-5 text-yellow-400");
        break;
    case "Error" :
        tmp = Icons.exclamationCircle("h-5 w-5 text-red-400");
        break;
    case "Success" :
        tmp = Icons.check("h-5 w-5 text-green-400");
        break;
    case "Info" :
        tmp = Icons.informationCircle("h-5 w-5 text-blue-400");
        break;
    
  }
  var tmp$1;
  switch (typ) {
    case "Warning" :
        tmp$1 = "text-yellow-700";
        break;
    case "Error" :
        tmp$1 = "text-red-700";
        break;
    case "Success" :
        tmp$1 = "text-green-700";
        break;
    case "Info" :
        tmp$1 = "";
        break;
    
  }
  var tmp$2;
  switch (typ) {
    case "Warning" :
        tmp$2 = "border-yellow-400 bg-yellow-50";
        break;
    case "Error" :
        tmp$2 = "border-red-400 bg-red-50";
        break;
    case "Success" :
        tmp$2 = "border-green-400 bg-green-50";
        break;
    case "Info" :
        tmp$2 = "border-blue-400 bg-white";
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: tmp,
                            className: "flex-shrink-0"
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: children !== undefined ? Caml_option.valFromOption(children) : (
                                      text !== undefined ? text : null
                                    ),
                                  className: U.tw([
                                        "text-sm text-left",
                                        tmp$1
                                      ])
                                }),
                            className: "ml-3"
                          })
                    ],
                    className: "flex"
                  }),
              className: U.tw([
                    "border-l-4 p-4 shadow rounded-md",
                    tmp$2
                  ])
            });
}

var make = InlineNotification;

export {
  make ,
}
/* Icons Not a pure module */
