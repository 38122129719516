// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DataSourceId from "../scalars/DataSourceId.mjs";

function serialize(t) {
  switch (t.TAG) {
    case "DataSourceIds" :
        return "dataSources___" + t.dataSources.map(DataSourceId.toString).join("||");
    case "Goals" :
        return "goals___" + t.goalIds.join("||");
    case "Campaigns" :
        return "campaigns___" + t.campaignIds.join("||");
    
  }
}

function parse(s) {
  var match = s.split("___");
  if (match.length !== 2) {
    return ;
  }
  var dataConfigType = match[0];
  var rawContent = match[1];
  switch (dataConfigType) {
    case "campaigns" :
        return {
                TAG: "Campaigns",
                campaignIds: rawContent.split("||")
              };
    case "dataSources" :
        return {
                TAG: "DataSourceIds",
                dataSources: rawContent.split("||").map(function (prim) {
                      return prim;
                    })
              };
    case "goals" :
        return {
                TAG: "Goals",
                goalIds: rawContent.split("||")
              };
    default:
      return ;
  }
}

export {
  serialize ,
  parse ,
}
/* No side effect */
