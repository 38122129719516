// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateTimeUtils from "../../utils/DateTimeUtils.mjs";

var datePeriodsForListbox = [
  {
    text: "Välj period"
  },
  {
    text: "Senaste 7 dagarna",
    value: "LAST_7_DAYS"
  },
  {
    text: "Senaste 28 dagarna",
    value: "LAST_28_DAYS"
  },
  {
    text: "Senaste 90 dagarna",
    value: "LAST_90_DAYS"
  },
  {
    text: "Allt",
    value: "LIFETIME"
  }
];

function getValidDate(date) {
  var date$1 = new Date(date).getTime();
  if (!isNaN(date$1)) {
    return date$1;
  }
  
}

function parse(str) {
  var parts = str.split(":");
  var match = parts[0];
  var match$1 = parts[1];
  if (match === undefined) {
    return ;
  }
  switch (match) {
    case "preset" :
        if (match$1 === undefined) {
          return ;
        }
        switch (match$1) {
          case "LAST_28_DAYS" :
              return {
                      TAG: "Preset",
                      _0: "LAST_28_DAYS"
                    };
          case "LAST_7_DAYS" :
              return {
                      TAG: "Preset",
                      _0: "LAST_7_DAYS"
                    };
          case "LAST_90_DAYS" :
              return {
                      TAG: "Preset",
                      _0: "LAST_90_DAYS"
                    };
          case "LIFETIME" :
              return {
                      TAG: "Preset",
                      _0: "LIFETIME"
                    };
          default:
            return ;
        }
    case "range" :
        if (match$1 === undefined) {
          return ;
        }
        var rangeParts = match$1.split("__");
        var match$2 = rangeParts[0];
        var match$3 = rangeParts[1];
        if (match$2 === undefined) {
          return ;
        }
        if (match$3 === undefined) {
          return ;
        }
        var match$4 = getValidDate(match$2);
        var match$5 = getValidDate(match$3);
        if (match$4 !== undefined && match$5 !== undefined) {
          return {
                  TAG: "DateRange",
                  start: match$4,
                  end: match$5
                };
        } else {
          return ;
        }
    case "single" :
        if (match$1 === undefined) {
          return ;
        }
        var date = getValidDate(match$1);
        if (date !== undefined) {
          return {
                  TAG: "SingleDate",
                  _0: date
                };
        } else {
          return ;
        }
    default:
      return ;
  }
}

function serialize(t) {
  switch (t.TAG) {
    case "SingleDate" :
        return "single:" + DateTimeUtils.formatShortDate(t._0);
    case "DateRange" :
        return "range:" + DateTimeUtils.formatShortDate(t.start) + "__" + DateTimeUtils.formatShortDate(t.end);
    case "Preset" :
        return "preset:" + t._0;
    
  }
}

function toGraphqlParams(t) {
  switch (t.TAG) {
    case "SingleDate" :
        var date = t._0;
        return {
                from: date,
                to: date,
                period: undefined
              };
    case "DateRange" :
        return {
                from: t.start,
                to: t.end,
                period: undefined
              };
    case "Preset" :
        return {
                from: undefined,
                to: undefined,
                period: t._0
              };
    
  }
}

var Param = {
  parse: parse,
  serialize: serialize,
  toGraphqlParams: toGraphqlParams
};

export {
  datePeriodsForListbox ,
  Param ,
}
/* DateTimeUtils Not a pure module */
