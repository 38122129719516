// Generated by ReScript, PLEASE EDIT WITH CARE


function tw(classes) {
  return classes.filter(function (c) {
                return c !== "";
              }).join(" ");
}

function uniqueId() {
  return Math.random().toString();
}

function nameToInitials(name) {
  return name.split(" ").slice(0, 2).map(function (part) {
                return part.slice(0, 1).toUpperCase();
              }).join("");
}

function capitalizeStr(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

var Link;

export {
  tw ,
  uniqueId ,
  Link ,
  nameToInitials ,
  capitalizeStr ,
}
/* No side effect */
