// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  if (s === "DAY" || s === "SOURCE_MEDIUM") {
    return s;
  }
  
}

function serialize(v) {
  return v;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
