// Generated by ReScript, PLEASE EDIT WITH CARE

import GetWeek from "date-fns/getWeek";
import GetQuarter from "date-fns/getQuarter";
import StartOfISOWeek from "date-fns/startOfISOWeek";
import LastDayOfISOWeek from "date-fns/lastDayOfISOWeek";

var shortDateFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      dateStyle: "short"
    });

var monthYearDateFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "long",
      year: "numeric"
    });

var monthDayDateFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "long",
      day: "numeric"
    });

var monthDayDateAsNumbersFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "numeric",
      day: "numeric"
    });

var dayDateFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      day: "numeric"
    });

var monthDateFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "long"
    });

var monthDateShortFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "short"
    });

var monthYearShortFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      month: "short",
      year: "2-digit"
    });

var yearFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      year: "numeric"
    });

var yearFormatterShort = new (Intl.DateTimeFormat)("sv-SE", {
      year: "2-digit"
    });

var shortDateAndTimeFormatter = new (Intl.DateTimeFormat)("sv-SE", {
      dateStyle: "short",
      timeStyle: "short"
    });

function formatShortDate(timestamp) {
  return shortDateFormatter.format(new Date(timestamp));
}

function formatMonthYear(timestamp) {
  return monthYearDateFormatter.format(new Date(timestamp));
}

function formatMonthYearShort(timestamp) {
  return monthYearShortFormatter.format(new Date(timestamp));
}

function formatMonthDay(timestamp) {
  return monthDayDateFormatter.format(new Date(timestamp));
}

function formatMonthDayAsNumbers(timestamp) {
  return monthDayDateAsNumbersFormatter.format(new Date(timestamp));
}

function formatDay(timestamp) {
  return dayDateFormatter.format(new Date(timestamp));
}

function formatMonth(timestamp) {
  return monthDateFormatter.format(new Date(timestamp));
}

function formatMonthShort(timestamp) {
  return monthDateShortFormatter.format(new Date(timestamp));
}

function formatYear(timestamp) {
  return yearFormatter.format(new Date(timestamp));
}

function formatYearShort(timestamp) {
  return yearFormatterShort.format(new Date(timestamp));
}

function formatShortDateAndTime(timestamp) {
  return shortDateAndTimeFormatter.format(new Date(timestamp));
}

var googleEndOfTime = new Date(2036, 1).getTime();

var dayInMs = 1000 * 60 * 60 * 24;

function daysBetween(firstTimestamp, secondTimestamp) {
  return Math.round((firstTimestamp - secondTimestamp) / dayInMs);
}

function formatWeek(timestamp) {
  return "v. " + GetWeek(new Date(timestamp), {"weekStartsOn": 1, "firstWeekContainsDate": 4}).toString();
}

function formatQuarter(timestamp) {
  return "Q" + GetQuarter(new Date(timestamp)).toString();
}

function getWeekDateInterval(timestamp) {
  var asDate = new Date(timestamp);
  return [
          StartOfISOWeek(asDate),
          LastDayOfISOWeek(asDate)
        ];
}

function padDateDigitIfNeeded(digit) {
  if (digit > 9 || digit < 0) {
    return digit.toString();
  } else {
    return "0" + digit.toString();
  }
}

export {
  shortDateFormatter ,
  monthYearDateFormatter ,
  monthDayDateFormatter ,
  monthDayDateAsNumbersFormatter ,
  dayDateFormatter ,
  monthDateFormatter ,
  monthDateShortFormatter ,
  monthYearShortFormatter ,
  yearFormatter ,
  yearFormatterShort ,
  shortDateAndTimeFormatter ,
  formatShortDate ,
  formatMonthYear ,
  formatMonthYearShort ,
  formatMonthDay ,
  formatMonthDayAsNumbers ,
  formatDay ,
  formatMonth ,
  formatMonthShort ,
  formatYear ,
  formatYearShort ,
  formatShortDateAndTime ,
  googleEndOfTime ,
  dayInMs ,
  daysBetween ,
  formatWeek ,
  formatQuarter ,
  getWeekDateInterval ,
  padDateDigitIfNeeded ,
}
/* shortDateFormatter Not a pure module */
