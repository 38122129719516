// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LoadingBar from "./LoadingBar.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function PendingIndicatorBar(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LoadingBar.make, {
                    visible: props.pending
                  }),
              className: "fixed left-0 top-0 w-full pointer-events-none z-20"
            });
}

var make = PendingIndicatorBar;

export {
  make ,
}
/* LoadingBar Not a pure module */
