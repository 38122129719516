// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Input from "./Input.mjs";
import * as Button from "./Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FramerMotion from "framer-motion";
import * as JsxRuntime from "react/jsx-runtime";
import * as InlineNotification from "./InlineNotification.mjs";

function LoginForm(props) {
  var onForgotPassword = props.onForgotPassword;
  var formError = props.formError;
  var loading = props.loading;
  var onSubmit = props.onSubmit;
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx(Input.make, {
                      name: "username",
                      label: "E-postadress",
                      value: props.username,
                      required: true,
                      placeholder: "Din e-postadress",
                      autoComplete: "username",
                      onChangeText: props.onChangeUsername,
                      validationState: props.usernameValidationState
                    }),
                JsxRuntime.jsx(Input.make, {
                      name: "password",
                      label: "Lösenord",
                      value: props.password,
                      typ: "Password",
                      required: true,
                      placeholder: "Ditt lösenord",
                      autoComplete: "current-password",
                      onChangeText: props.onChangePassword,
                      validationState: props.passwordValidationState
                    }),
                formError !== undefined ? JsxRuntime.jsx(FramerMotion.motion.div, {
                        children: Caml_option.some(JsxRuntime.jsx(InlineNotification.make, {
                                  text: formError,
                                  typ: "Error"
                                })),
                        className: "animate-fade-in",
                        layout: true
                      }, "form-error") : null,
                JsxRuntime.jsx(Button.make, {
                      text: loading !== undefined && loading ? "Loggar in..." : "Logga in",
                      typ: "Submit",
                      loading: loading,
                      disabled: loading !== undefined && loading ? ({
                            TAG: "Hidden",
                            _0: true
                          }) : undefined,
                      layout: "FullWidth",
                      oneShotSetShowState: formError !== undefined ? "Error" : undefined
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("button", {
                            children: "Glömt ditt lösenord?",
                            className: "underline text-sm text-blue-600",
                            type: "button",
                            onClick: (function (param) {
                                onForgotPassword();
                              })
                          }),
                      className: "mt-6"
                    })
              ],
              className: "space-y-6",
              action: "POST",
              onSubmit: (function (e) {
                  e.preventDefault();
                  onSubmit();
                })
            });
}

var make = LoginForm;

export {
  make ,
}
/* Input Not a pure module */
