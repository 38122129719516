// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Motion from "../bindings/Motion.mjs";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FramerMotion from "framer-motion";
import * as JsxRuntime from "react/jsx-runtime";

function makeToast(title, message, typ) {
  return {
          title: title,
          message: message,
          typ: typ
        };
}

var toastState = Recoil.atom({
      key: "toastState",
      default: []
    });

function useAddToast() {
  var match = Recoil.useRecoilState(toastState);
  var setToastState = match[1];
  return React.useCallback((function (config) {
                var toast_id = U.uniqueId();
                var toast_title = config.title;
                var toast_message = config.message;
                var toast_typ = config.typ;
                var toast = {
                  id: toast_id,
                  title: toast_title,
                  message: toast_message,
                  typ: toast_typ
                };
                setToastState(function (toasts) {
                      return [toast].concat(toasts);
                    });
              }), [setToastState]);
}

function Toast$SingleToast(props) {
  var icon = props.icon;
  var onClose = props.onClose;
  var __timeout = props.timeout;
  var timeout = __timeout !== undefined ? __timeout : 10000;
  React.useEffect((function () {
          var timeoutId = setTimeout((function () {
                  onClose();
                }), timeout);
          return (function () {
                    clearTimeout(timeoutId);
                  });
        }), []);
  var tmp;
  switch (props.typ) {
    case "Neutral" :
        tmp = "";
        break;
    case "Success" :
        tmp = "text-green-500";
        break;
    case "Warning" :
        tmp = "text-yellow-500";
        break;
    case "Error" :
        tmp = "text-red-500";
        break;
    
  }
  return JsxRuntime.jsx(FramerMotion.motion.li, {
              children: Caml_option.some(JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: icon !== undefined ? Caml_option.valFromOption(icon) : null,
                                      className: U.tw([
                                            "flex-shrink-0",
                                            tmp
                                          ])
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("p", {
                                              children: props.title,
                                              className: "text-sm font-medium text-gray-900"
                                            }),
                                        JsxRuntime.jsx("p", {
                                              children: props.message,
                                              className: "mt-1 text-sm text-gray-500"
                                            })
                                      ],
                                      className: "ml-3 w-0 flex-1 pt-0.5"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs("button", {
                                            children: [
                                              JsxRuntime.jsx("span", {
                                                    children: "Close",
                                                    className: "sr-only"
                                                  }),
                                              Icons.solidX(undefined)
                                            ],
                                            className: "bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                                            type: "button",
                                            onClick: (function (param) {
                                                onClose();
                                              })
                                          }),
                                      className: "ml-4 flex-shrink-0 flex"
                                    })
                              ],
                              className: "flex items-start"
                            }),
                        className: "p-4"
                      })),
              className: "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-2",
              layout: "postion",
              initial: Motion.makeAnimationConfig(0, undefined, undefined, -5),
              animate: Motion.makeAnimationConfig(1, undefined, undefined, 0),
              exit: Motion.makeAnimationConfig(0, undefined, undefined, 0)
            }, props.id);
}

var SingleToast = {
  make: Toast$SingleToast
};

function Toast$ToastList(props) {
  var onToastClosed = props.onToastClosed;
  return JsxRuntime.jsx(FramerMotion.AnimateSharedLayout, {
              children: JsxRuntime.jsx(FramerMotion.motion.ul, {
                    children: Caml_option.some(JsxRuntime.jsx(FramerMotion.AnimatePresence, {
                              children: Caml_option.some(props.toasts.map(function (toast) {
                                        var match = toast.typ;
                                        var tmp;
                                        switch (match) {
                                          case "Neutral" :
                                              tmp = null;
                                              break;
                                          case "Success" :
                                              tmp = Icons.checkCircle(undefined);
                                              break;
                                          case "Warning" :
                                              tmp = Icons.exclamationCircle("h-5 w-5 text-yellow-500");
                                              break;
                                          case "Error" :
                                              tmp = Icons.exclamationCircle("h-5 w-5 text-red-500");
                                              break;
                                          
                                        }
                                        return JsxRuntime.jsx(Toast$SingleToast, {
                                                    id: toast.id,
                                                    title: toast.title,
                                                    message: toast.message,
                                                    typ: toast.typ,
                                                    onClose: (function () {
                                                        onToastClosed(toast.id);
                                                      }),
                                                    icon: Caml_option.some(tmp)
                                                  }, toast.id);
                                      }))
                            })),
                    className: "fixed inset-0 flex items-end flex-col justify-end sm:justify-start px-4 py-6 pointer-events-none sm:p-6 z-30"
                  })
            });
}

var ToastList = {
  make: Toast$ToastList
};

function Toast(props) {
  var match = Recoil.useRecoilState(toastState);
  var setToasts = match[1];
  return JsxRuntime.jsx(Toast$ToastList, {
              toasts: match[0],
              onToastClosed: (function (id) {
                  setToasts(function (prev) {
                        return prev.filter(function (toast) {
                                    return toast.id !== id;
                                  });
                      });
                })
            });
}

var make = Toast;

export {
  makeToast ,
  toastState ,
  useAddToast ,
  SingleToast ,
  ToastList ,
  make ,
}
/* toastState Not a pure module */
