// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  switch (s) {
    case "count_ascending" :
        return {
                TAG: "Count",
                _0: "ASCENDING"
              };
    case "count_descending" :
        return {
                TAG: "Count",
                _0: "DESCENDING"
              };
    case "day" :
        return "Day";
    default:
      return ;
  }
}

function serialize(v) {
  if (typeof v !== "object") {
    return "day";
  } else if (v._0 === "ASCENDING") {
    return "count_ascending";
  } else {
    return "count_descending";
  }
}

export {
  parse ,
  serialize ,
}
/* No side effect */
