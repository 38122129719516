// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptCore from "@rescript/core/src/RescriptCore.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";

function useCurrentOrganizationSlugOpt() {
  var match = RescriptReactRouter.useUrl(undefined, undefined).path;
  if (!match) {
    return ;
  }
  switch (match.hd) {
    case "_reports" :
    case "backoffice" :
        break;
    case "o" :
        var match$1 = match.tl;
        if (match$1) {
          return match$1.hd;
        } else {
          return ;
        }
    default:
      return ;
  }
  var match$2 = match.tl;
  if (!match$2) {
    return ;
  }
  if (match$2.hd !== "o") {
    return ;
  }
  var match$3 = match$2.tl;
  if (match$3) {
    return match$3.hd;
  }
  
}

function useCurrentOrganizationSlug() {
  var organizationSlug = useCurrentOrganizationSlugOpt();
  if (organizationSlug !== undefined) {
    return organizationSlug;
  } else {
    return RescriptCore.panic("Not on organization route.");
  }
}

export {
  useCurrentOrganizationSlug ,
  useCurrentOrganizationSlugOpt ,
}
/* RescriptReactRouter Not a pure module */
