// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Skeletons$Text(props) {
  var tmp;
  tmp = props.size === "Paragraph" ? "h-6 w-52" : "h-9 w-96";
  return JsxRuntime.jsx("div", {
              className: U.tw([
                    "rounded-md flex-1 min-w-0 animate-pulse bg-gray-300 max-w-full",
                    tmp
                  ])
            });
}

var $$Text = {
  make: Skeletons$Text
};

function Skeletons$PageTitle(props) {
  var breadcrumbs = props.breadcrumbs;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                breadcrumbs !== undefined && breadcrumbs ? JsxRuntime.jsx(Skeletons$Text, {
                        size: "Paragraph"
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Skeletons$Text, {
                            size: "Title"
                          }),
                      className: "pt-2"
                    })
              ]
            });
}

var PageTitle = {
  make: Skeletons$PageTitle
};

export {
  $$Text ,
  PageTitle ,
}
/* react/jsx-runtime Not a pure module */
