// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.mjs";
import * as HandleMutationErrors_mutation_graphql from "../__generated__/HandleMutationErrors_mutation_graphql.mjs";

var convertFragment = HandleMutationErrors_mutation_graphql.Internal.convertFragment;

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(HandleMutationErrors_mutation_graphql.node, convertFragment, fRef);
}

var MutationErrorInterfaceFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  readInline: readInline
};

function use() {
  var addToast = Toast.useAddToast();
  var handleActualErrors = React.useCallback((function (maybeErrors, fragmentRefs) {
          var hadError = maybeErrors !== undefined ? (maybeErrors.forEach(function (param) {
                    addToast(Toast.makeToast("Något gick fel", "Hoppsan, något gick fel här! Försök igen, eller kontakta supporten.", "Error"));
                  }), true) : false;
          var data = readInline(fragmentRefs);
          var errors = data.presentableErrors;
          if (errors !== undefined) {
            errors.forEach(function (error) {
                  addToast(Toast.makeToast(Core__Option.getOr(error.title, "Något gick fel"), error.message, "Error"));
                });
            return true;
          } else {
            return hadError;
          }
        }), [addToast]);
  var handleSingleError = React.useCallback((function (_error) {
          addToast(Toast.makeToast("Något gick fel", "Hoppsan, något gick fel här! Försök igen, eller kontakta supporten.", "Error"));
        }), [addToast]);
  return [
          handleActualErrors,
          handleSingleError
        ];
}

export {
  MutationErrorInterfaceFragment ,
  use ,
}
/* Toast Not a pure module */
