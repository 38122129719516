// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function reportIntervalFromString(str) {
  switch (str) {
    case "monthly" :
        return "Monthly";
    case "quarterly" :
        return "Quarterly";
    case "weekly" :
        return "Weekly";
    case "yearly" :
        return "Yearly";
    default:
      return ;
  }
}

function deserializeReport(raw) {
  var parts = raw.split("__");
  var match = parts[0];
  if (match === undefined) {
    return ;
  }
  switch (match) {
    case "CustomReport" :
        var parts$1 = parts.slice(1);
        if (parts$1.length !== 2) {
          return ;
        }
        var name = parts$1[0];
        if (name === "-") {
          var date = parts$1[1];
          return {
                  TAG: "CustomReport",
                  date: date
                };
        }
        var date$1 = parts$1[1];
        return {
                TAG: "CustomReport",
                name: name,
                date: date$1
              };
    case "FullOverview" :
        var parts$2 = parts.slice(1);
        if (parts$2.length !== 2) {
          return ;
        }
        var intervalRaw = parts$2[0];
        var intervalStart = parts$2[1];
        var interval = reportIntervalFromString(intervalRaw);
        if (interval !== undefined) {
          return {
                  TAG: "FullOverview",
                  interval: interval,
                  intervalStart: intervalStart
                };
        } else {
          return ;
        }
    case "TestReport" :
        return "TestReport";
    default:
      return ;
  }
}

var Not_implemented = /* @__PURE__ */Caml_exceptions.create("ReportsRouteUtils.Param.Not_implemented");

function serialize(_report) {
  throw {
        RE_EXN_ID: Not_implemented,
        Error: new Error()
      };
}

function parse(str) {
  return deserializeReport(str);
}

var Param = {
  Not_implemented: Not_implemented,
  serialize: serialize,
  parse: parse
};

export {
  reportIntervalFromString ,
  deserializeReport ,
  Param ,
}
/* No side effect */
