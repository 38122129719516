// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as RouterUtils from "./RouterUtils.mjs";

function use() {
  var slug = sessionStorage.getItem("last_visited_organization");
  if ((slug == null) || slug === "") {
    return ;
  } else {
    return slug;
  }
}

function useTrackLastVisitedOrganization() {
  var slug = RouterUtils.useCurrentOrganizationSlugOpt();
  React.useEffect((function () {
          if (slug !== undefined) {
            sessionStorage.setItem("last_visited_organization", slug);
          }
          
        }), [slug]);
}

export {
  use ,
  useTrackLastVisitedOrganization ,
}
/* react Not a pure module */
