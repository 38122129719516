// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DataConfig from "./DataConfig.mjs";
import * as Core__Float from "@rescript/core/src/Core__Float.mjs";
import * as DataSourceId from "../scalars/DataSourceId.mjs";
import * as SingleMetricDrilldownQuery_graphql from "../__generated__/SingleMetricDrilldownQuery_graphql.mjs";
import * as SingleMetricDrilldown_metrics_graphql from "../__generated__/SingleMetricDrilldown_metrics_graphql.mjs";

var parse = SingleMetricDrilldownQuery_graphql.Utils.metricEnum_fromString;

var parse$1 = SingleMetricDrilldown_metrics_graphql.Utils.metricalPartsTypeEnum_fromString;

function serialize(t) {
  return t;
}

var PartsParam = {
  parse: parse$1,
  serialize: serialize
};

function makeMetricDataConfiguration(sourcesToInclude, date) {
  var makeInput = function (to, from, period) {
    return {
            sources: [],
            dataSources: sourcesToInclude,
            to: to,
            from: from,
            period: period
          };
  };
  switch (date.TAG) {
    case "SingleDate" :
        var date$1 = date._0;
        return makeInput(date$1, date$1, undefined);
    case "DateRange" :
        return makeInput(date.end, date.start, undefined);
    case "Preset" :
        return makeInput(undefined, undefined, date._0);
    
  }
}

var Utils = {
  makeMetricDataConfiguration: makeMetricDataConfiguration
};

function parse$2(str) {
  var match = str.split("::");
  if (match.length !== 4) {
    return ;
  }
  var metricRaw = match[0];
  var fromRaw = match[1];
  var toRaw = match[2];
  var dataConfigRaw = match[3];
  var match$1 = parse(metricRaw);
  var match$2 = Core__Float.fromString(fromRaw);
  var match$3 = Core__Float.fromString(toRaw);
  var match$4 = DataConfig.parse(dataConfigRaw);
  if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined) {
    return {
            metric: match$1,
            from: match$2,
            to: match$3,
            dataConfig: match$4
          };
  }
  
}

function serialize$1(t) {
  var match = t.dataConfig;
  var tmp;
  switch (match.TAG) {
    case "DataSourceIds" :
        tmp = "dataSources___" + match.dataSources.map(DataSourceId.toString).join("||");
        break;
    case "Goals" :
        tmp = "goals___" + match.goalIds.join("||");
        break;
    case "Campaigns" :
        tmp = "campaigns___" + match.campaignIds.join("||");
        break;
    
  }
  return t.metric + "::" + t.from.toString() + "::" + t.to.toString() + "::" + tmp;
}

var TargetMetric = {
  parse: parse$2,
  serialize: serialize$1
};

var MetricParam = {};

export {
  MetricParam ,
  PartsParam ,
  Utils ,
  TargetMetric ,
}
/* SingleMetricDrilldownQuery_graphql Not a pure module */
