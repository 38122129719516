// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayEnv from "../RelayEnv.mjs";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as RouteDeclarations from "./__generated__/RouteDeclarations.mjs";
import * as RelayRouter__AssetPreloader from "rescript-relay-router/src/RelayRouter__AssetPreloader.mjs";

var preparedAssetsMap = {};

var match = RelayRouter.Router.make(RouteDeclarations.make(undefined), RelayRouter.RouterEnvironment.makeBrowserEnvironment(), RelayEnv.environment, RelayRouter__AssetPreloader.makeClientAssetPreloader(preparedAssetsMap));

var routerContext = match[1];

export {
  preparedAssetsMap ,
  routerContext ,
}
/* match Not a pure module */
