// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Toast from "./components/Toast.mjs";
import * as React from "react";
import * as Confirm from "./components/Confirm.mjs";
import * as Skeletons from "./components/Skeletons.mjs";
import * as LoginModal from "./components/LoginModal.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as ErrorBoundary from "./layout/ErrorBoundary.mjs";
import * as FramerMotion from "framer-motion";
import * as JsxRuntime from "react/jsx-runtime";
import * as PendingIndicatorBar from "./components/PendingIndicatorBar.mjs";

window.history.scrollRestoration = "manual";

function App(props) {
  return JsxRuntime.jsx(FramerMotion.AnimateSharedLayout, {
              children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(LoginModal.make, {}),
                      JsxRuntime.jsx(Confirm.make, {}),
                      JsxRuntime.jsx(ErrorBoundary.make, {
                            children: JsxRuntime.jsx(React.Suspense, {
                                  children: Caml_option.some(JsxRuntime.jsx(RelayRouter.RouteRenderer.make, {
                                            renderPending: (function (pending) {
                                                return JsxRuntime.jsx(PendingIndicatorBar.make, {
                                                            pending: pending
                                                          });
                                              })
                                          })),
                                  fallback: Caml_option.some(JsxRuntime.jsx(Skeletons.PageTitle.make, {}))
                                }),
                            fallback: (function (param) {
                                return "Error!";
                              })
                          }),
                      JsxRuntime.jsx(Toast.make, {})
                    ]
                  })
            });
}

var make = App;

export {
  make ,
}
/*  Not a pure module */
