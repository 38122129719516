// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NetworkUtils from "./utils/NetworkUtils.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as RelayRuntime from "relay-runtime";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

var network = RelayRuntime.Network.create(NetworkUtils.fetchQuery, NetworkUtils.subscribeFn);

var nodeInterfaceMissingFieldHandler = RescriptRelay.MissingFieldHandler.makeLinkedMissingFieldHandler(function (field, record, args, _store) {
      var match = field.name;
      var match$1 = args.id;
      if ((record == null) || !(match === "node" && record.getType() === RelayRuntime.ROOT_TYPE)) {
        return Js_null_undefined.fromOption(undefined);
      } else {
        return Js_null_undefined.fromOption((match$1 == null) ? undefined : Caml_option.some(match$1));
      }
    });

var nodeInterfaceNodesFieldMissingFieldHandler = RescriptRelay.MissingFieldHandler.makePluralLinkedMissingFieldHandler(function (field, record, args, _store) {
      var match = field.name;
      var match$1 = args.ids;
      if ((record == null) || !(match === "nodes" && record.getType() === RelayRuntime.ROOT_TYPE)) {
        return Js_null_undefined.fromOption(undefined);
      } else {
        return Js_null_undefined.fromOption((match$1 == null) ? undefined : Caml_option.some(match$1));
      }
    });

function makeEnvironmentWithNetwork(network) {
  return RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(), 50, 21600000), undefined, undefined, [
              nodeInterfaceMissingFieldHandler,
              nodeInterfaceNodesFieldMissingFieldHandler
            ], undefined, undefined);
}

var environment = makeEnvironmentWithNetwork(network);

export {
  network ,
  nodeInterfaceMissingFieldHandler ,
  nodeInterfaceNodesFieldMissingFieldHandler ,
  makeEnvironmentWithNetwork ,
  environment ,
}
/* network Not a pure module */
