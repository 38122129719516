// Generated by ReScript, PLEASE EDIT WITH CARE


var standardLabelClasses = "block text-sm font-medium text-gray-700";

var standardGradientBackground = "bg-informind-500";

export {
  standardLabelClasses ,
  standardGradientBackground ,
}
/* No side effect */
