// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DataConfig from "./DataConfig.mjs";
import * as Core__Array from "@rescript/core/src/Core__Array.mjs";
import * as Core__Float from "@rescript/core/src/Core__Float.mjs";
import * as TimelineQuery_graphql from "../__generated__/TimelineQuery_graphql.mjs";

function serialize(t) {
  return "m:" + t._0.map(function (m) {
                return m;
              }).join(",");
}

function parse(s) {
  var match = s.split(":");
  if (match.length !== 2) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 !== "m") {
    return ;
  }
  var metricsRaw = match[1];
  return {
          TAG: "Metrics",
          _0: Core__Array.keepSome(metricsRaw.split(",").map(function (m) {
                    return TimelineQuery_graphql.Utils.metricEnum_fromString(m);
                  }))
        };
}

var TimelineSource = {
  serialize: serialize,
  parse: parse
};

function parse$1(str) {
  var match = str.split("::");
  if (match.length !== 5) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 !== "timeline") {
    return ;
  }
  var fromRaw = match[1];
  var toRaw = match[2];
  var sourcesRaw = match[3];
  var dataConfigRaw = match[4];
  var match$2 = Core__Float.fromString(fromRaw);
  var match$3 = Core__Float.fromString(toRaw);
  var match$4 = parse(sourcesRaw);
  var match$5 = DataConfig.parse(dataConfigRaw);
  if (match$2 !== undefined && match$3 !== undefined && match$4 !== undefined && match$5 !== undefined) {
    return {
            from: match$2,
            to: match$3,
            timelineSources: match$4,
            timelineDataSources: match$5
          };
  }
  
}

function serialize$1(t) {
  return "timeline::" + t.from.toString() + "::" + t.to.toString() + "::" + serialize(t.timelineSources) + "::" + DataConfig.serialize(t.timelineDataSources);
}

var Config = {
  parse: parse$1,
  serialize: serialize$1
};

export {
  TimelineSource ,
  Config ,
}
/* TimelineQuery_graphql Not a pure module */
