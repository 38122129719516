// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Recoil from "recoil";
import * as JsxRuntime from "react/jsx-runtime";

function makeItem(title, icon, content, onDone, variantOpt, okTextOpt) {
  var variant = variantOpt !== undefined ? variantOpt : "Default";
  var okText = okTextOpt !== undefined ? okTextOpt : "Ok";
  return {
          id: U.uniqueId(),
          title: title,
          icon: icon,
          content: content,
          onDone: onDone,
          variant: variant,
          okText: okText
        };
}

var confirmState = Recoil.atom({
      key: "confirmState",
      default: []
    });

function useConfirm() {
  var match = Recoil.useRecoilState(confirmState);
  var setConfirmState = match[1];
  return React.useCallback((function (config) {
                setConfirmState(function (confirms) {
                      return [config].concat(confirms);
                    });
              }), [setConfirmState]);
}

function Confirm(props) {
  var match = Recoil.useRecoilState(confirmState);
  var setConfirm = match[1];
  var confirming = match[0];
  if (confirming.length === 0) {
    return null;
  }
  var confirm = confirming[0];
  if (confirm === undefined) {
    return null;
  }
  var removeItem = function () {
    setConfirm(function (items) {
          return items.filter(function (item) {
                      return item.id !== confirm.id;
                    });
        });
  };
  var match$1 = confirm.variant;
  var tmp;
  tmp = match$1 === "Destructive" ? "Destructive" : "Primary";
  return JsxRuntime.jsx(Modal.make, {
              title: confirm.title,
              icon: confirm.icon,
              children: confirm.content,
              actions: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Button.make, {
                            text: confirm.okText,
                            onClick: (function () {
                                confirm.onDone("Ok");
                                removeItem();
                              }),
                            layout: "FullWidth",
                            variant: tmp
                          }),
                      JsxRuntime.jsx(Button.make, {
                            text: "Avbryt",
                            onClick: (function () {
                                confirm.onDone("Cancel");
                                removeItem();
                              }),
                            layout: "FullWidth",
                            variant: "Outlined"
                          })
                    ],
                    className: "flex flex-row space-x-2"
                  }),
              open_: true,
              stack: "OnTop"
            }, confirm.id);
}

var make = Confirm;

export {
  makeItem ,
  confirmState ,
  useConfirm ,
  make ,
}
/* confirmState Not a pure module */
