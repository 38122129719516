// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  switch (s) {
    case "all" :
        return "All";
    case "matched_only" :
        return "MatchedOnly";
    case "unmatched_only" :
        return "UnmatchedOnly";
    default:
      return ;
  }
}

function serialize(v) {
  switch (v) {
    case "All" :
        return "all";
    case "UnmatchedOnly" :
        return "unmatched_only";
    case "MatchedOnly" :
        return "matched_only";
    
  }
}

export {
  parse ,
  serialize ,
}
/* No side effect */
