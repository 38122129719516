// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

function Card(props) {
  var __shadow = props.shadow;
  var footer = props.footer;
  var header = props.header;
  var shadow = __shadow !== undefined ? __shadow : "Medium";
  var tmp;
  tmp = shadow === "Medium" ? "shadow" : "shadow-xl";
  return JsxRuntime.jsxs("div", {
              children: [
                header !== undefined ? JsxRuntime.jsx("div", {
                        children: Caml_option.valFromOption(header),
                        className: U.tw(["px-4 py-5 sm:px-6 bg-gray-50"])
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: "px-4 py-5 sm:p-6"
                    }),
                footer !== undefined ? JsxRuntime.jsx("div", {
                        children: Caml_option.valFromOption(footer),
                        className: U.tw(["px-4 py-4 sm:px-6 mt-auto rounded-b-md bg-gray-50"])
                      }) : null
              ],
              className: U.tw([
                    "bg-white rounded-lg flex flex-col items-stretch",
                    props.height !== undefined ? "h-full" : "",
                    tmp
                  ])
            });
}

var make = Card;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
