// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  if (s === "CONVERSIONS" || s === "SESSIONS") {
    return s;
  }
  
}

function serialize(v) {
  return v;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
