// Generated by ReScript, PLEASE EDIT WITH CARE


function serialize(t) {
  return t;
}

function parse(str) {
  switch (str) {
    case "CreatedOnServiceAtDate_ASC" :
        return "CreatedOnServiceAtDate_ASC";
    case "CreatedOnServiceAtDate_DESC" :
        return "CreatedOnServiceAtDate_DESC";
    case "Name_ASC" :
        return "Name_ASC";
    case "Name_DESC" :
        return "Name_DESC";
    default:
      return ;
  }
}

var SortOrderParam = {
  serialize: serialize,
  parse: parse
};

function decodeCampaignType(str) {
  switch (str) {
    case "FacebookCampaign" :
        return "FacebookCampaign";
    case "GoogleAdsCampaign" :
        return "GoogleAdsCampaign";
    case "LinkedInCampaign" :
        return "LinkedInCampaign";
    default:
      return ;
  }
}

function serialize$1(t) {
  return t;
}

var CampaignTypeParam = {
  serialize: serialize$1,
  parse: decodeCampaignType
};

export {
  SortOrderParam ,
  decodeCampaignType ,
  CampaignTypeParam ,
}
/* No side effect */
