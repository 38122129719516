// Generated by ReScript, PLEASE EDIT WITH CARE


var AnimateSharedLayout = {};

var AnimatePresence = {};

function makeAnimationConfig(opacity, scale, x, y) {
  return {
          opacity: opacity,
          scale: scale,
          x: x,
          y: y
        };
}

function makeSpringTransitionConfig(damping, stiffness) {
  return {
          type: "spring",
          damping: damping,
          stiffness: stiffness
        };
}

var Div = {};

var Tr = {};

var Img = {};

var Span = {};

var P = {};

var Ul = {};

var Li = {};

var M = {
  Div: Div,
  Tr: Tr,
  Img: Img,
  Span: Span,
  P: P,
  Ul: Ul,
  Li: Li
};

export {
  AnimateSharedLayout ,
  AnimatePresence ,
  makeAnimationConfig ,
  makeSpringTransitionConfig ,
  M ,
}
/* No side effect */
